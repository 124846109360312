.groupmembers {
    background-color: #1f1f1f;
    background-image: image('../../assets/images/watermelon-fruit-sliced-1296x728-header-1296x728.jpg');
    background-attachment: fixed;
    background-size: cover;
    line-height: 1.8;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.deckdisplay {
    align-items: center;
    padding-left: 10rem;
    padding-right: 10rem;
}

.group_member {
    display: flex;
}